"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ingNameToInfoKeys = {
  "acesulfame K": ["acesulfame K"],
  "acesulfame potassium": ["acesulfame K"],
  Sunette: ["acesulfame K"],
  "acetic acid": ["sorbic acid"],
  "acid casein": ["acid casein"],
  acidulant: ["acidulant"],
  "acrylic acid": ["acrylic acid"],
  "acrylate-acrylamide resin": ["acrylic acid"],
  "activated carbon": ["activated carbon"],
  "adipic acid": ["adipic acid"],
  "hexanedioic acid": ["adipic acid"],
  agar: ["agar"],
  "Japanese isinglass": ["agar"],
  "agar-agar": ["agar"],
  alanine: ["alanine"],
  albumen: ["albumen"],
  albumin: ["albumin"],
  algin: ["algin"],
  alginate: ["algin"],
  "alginic acid": ["alginic acid"],
  "alpha tocopherol": ["vitamin E"],
  alum: ["alum"],
  "potash alum": ["alum"],
  "aluminum ammonium": ["alum"],
  "potassium sulfate": ["alum"],
  "amino acid": ["amino acid"],
  amylase: ["amylase"],
  annatto: ["annatto"],
  "annatto extract": ["annatto"],
  "annatto seed": ["annatto"],
  norbixin: ["annatto"],
  "anticaking agent": ["anticaking agent"],
  "free-flow agent": ["anticaking agent"],
  "flow aid": ["anticaking agent"],
  antioxidant: ["antioxidant"],
  arabic: ["arabic"],
  acacia: ["arabic"],
  "acacia vera": ["arabic"],
  "gum arabic": ["arabic"],
  catechu: ["arabic"],
  "Egyptian thorn": ["arabic"],
  arginine: ["arginine"],
  "artificial coloring": ["artificial coloring"],
  "artificial flavor": ["artificial flavor"],
  "ascorbic acid": ["vitamin C"],
  aspartame: ["aspartame"],
  Nutrasweet: ["aspartame"],
  Equal: ["aspartame"],
  "aspartic acid": ["aspartic acid"],
  "autolyzed yeast extract": ["autolyzed yeast extract"],
  "yeast autolyzates": ["autolyzed yeast extract"],
  "baking powder": ["baking powder"],
  "baking soda": ["sodium bicarbonate"],
  beeswax: ["beeswax"],
  "beet sugar": ["sucrose"],
  bentonite: ["bentonite"],
  "benzoyl peroxide": ["benzoyl peroxide"],
  "beta-carotene": ["beta-carotene"],
  "Provitamin A": ["beta-carotene"],
  BHA: ["butylated hydroxyanisole"],
  BHT: ["butylated hydroxytoluene"],
  bioflavinoids: ["bioflavinoids"],
  hesperidin: ["bioflavinoids"],
  rutin: ["bioflavinoids"],
  "vitamin P complex": ["bioflavinoids"],
  biotin: ["biotin"],
  "vitamin B factor": ["biotin"],
  "Brewer's yeast": ["Brewer's yeast"],
  bromelain: ["bromelain"],
  bromelin: ["bromelain"],
  "butylated hydroxyanisole": ["butylated hydroxyanisole"],
  "butylated hydroxytoluene": ["butylated hydroxytoluene"],
  "butyric acid": ["butyric acid"],
  "n-butyric acid": ["butyric acid"],
  "butanoic acid": ["butyric acid"],
  "calcium carbonate": ["calcium carbonate"],
  "calcium caseinate": ["calcium caseinate"],
  "calcium chloride": ["calcium chloride"],
  "calcium dichloride": ["calcium chloride"],
  E509: ["calcium chloride"],
  "calcium pantothenate": ["pantothenic acid"],
  "calcium phosphates": ["calcium phosphates"],
  "calcium propionate": ["calcium propionate"],
  "propanoic acid": ["propionic acid"],
  "calcium salt": ["calcium propionate"],
  "calcium stearate": ["calcium stearate"],
  "calcium sulfate": ["calcium sulfate"],
  "calcium sulfate anhydrous": ["calcium sulfate"],
  "plaster of Paris": ["calcium sulfate"],
  "candelilla wax": ["candelilla wax"],
  "cane sugar": ["sucrose"],
  sugar: ["cane sugar", "refined beet sugar", "sucrose"],
  sucrose: ["sucrose"],
  "white sugar": ["cane sugar"],
  "brown sugar": ["cane sugar"],
  "capric acid": ["capric acid"],
  "n-decanoic acid": ["capric acid"],
  "caproic acid": ["caproic acid"],
  "n-hexanoic acid": ["caproic acid"],
  "caprylic acid": ["caprylic acid"],
  "n-octanoic acid": ["caprylic acid"],
  "caramel color": ["caramel color"],
  carbohydrate: ["carbohydrate"],
  carmine: ["carmine"],
  "carminic acid": ["carmine", "cochineal"],
  "carnauba wax": ["carnauba wax"],
  carotene: ["beta-carotene"],
  carotenoid: ["carotenoid"],
  carrageenan: ["carrageenan"],
  "Chondrus extract": ["carrageenan"],
  "Irish moss": ["carrageenan"],
  casein: ["sodium caseinate"],
  "cellulose gum": ["cellulose gum"],
  CMC: ["cellulose gum"],
  carboxymethylcellulose: ["cellulose gum"],
  "sodium carboxymethyl cellulose": ["cellulose gum"],
  charcoal: ["charcoal"],
  "activated charcoal": ["charcoal"],
  "chelating agent": ["sequestering agent"],
  "citric acid": ["citric acid"],
  "clarifying agent": ["clarifying agent"],
  "fining agent": ["clarifying agent"],
  clarifier: ["clarifying agent"],
  cochineal: ["cochineal"],
  "cocoa butter": ["cocoa butter"],
  coenzyme: ["coenzyme"],
  color: ["color"],
  "coloring adjunct": ["color"],
  "color stabilizer": ["color"],
  "color fixative": ["color"],
  "color-retention agent": ["color"],
  "corn gluten": ["corn gluten"],
  "corn gluten meal": ["corn gluten"],
  "cream of tartar": ["cream of tartar"],
  "potassium acid tartrate": ["cream of tartar"],
  "potassium hydrogen tartrate": ["cream of tartar"],
  "potassium bitartrate": ["cream of tartar"],
  "cultured dextrose": ["cultured dextrose"],
  "cultured corn sugar": ["cultured dextrose"],
  "curing agent": ["curing agent"],
  "pickling agent": ["curing agent"],
  "cysteine/cystine": ["L-cysteine/L-cystine"],
  Datem: ["monoglyceride"],
  "Defoaming agent": ["Defoaming agent"],
  DevanSweet: ["DevanSweet"],
  dextrose: ["dextrose"],
  glucose: ["dextrose"],
  "D-glucose": ["dextrose"],
  "corn sugar": ["dextrose"],
  "grape sugar": ["dextrose"],
  "rice sugar": ["dextrose"],
  "diatomaceous earth": ["silicon dioxide"],
  kieselguhr: ["diatomaceous earth"],
  diglyceride: ["diglyceride"],
  "disodium inosinate": ["disodium inosinate"],
  distillation: ["distillation"],
  "distilled vinegar": ["distilled vinegar"],
  "white vinegar": ["distilled vinegar"],
  "white distilled vinegar": ["distilled vinegar"],
  "spirit vinegar": ["distilled vinegar"],
  "grain vinegar": ["distilled vinegar"],
  "dough conditioner": ["dough conditioner"],
  "dough strengthener": ["dough conditioner"],
  "yeast food": ["yeast food"],
  "drying agent": ["drying agent"],
  EDTA: ["EDTA"],
  emulsifier: ["emulsifier"],
  "surface acting agent": ["emulsifier"],
  surfactant: ["emulsifier"],
  "wetting agent": ["emulsifier"],
  enzyme: ["enzyme"],
  "essential oil": ["essential oil"],
  "light oil": ["essential oil"],
  ethanol: ["ethyl alcohol"],
  "ethyl alcohol": ["ethyl alcohol"],
  "grain alcohol": ["ethyl alcohol"],
  "ethyl vanillin": ["vanillin"],
  fat: ["fat"],
  "fatty acid": ["fatty acid"],
  fermentation: ["fermentation"],
  "fermentation aid": ["fermentation aid"],
  "malting aid": ["fermentation aid"],
  "firming agent": ["firming agent"],
  "flavor enhancer": ["flavor enhancer"],
  "foaming agent": ["foaming agent"],
  "whipping agent": ["foaming agent"],
  "folic acid": ["folic acid"],
  folacin: ["folic acid"],
  "pteroyl glutamic acid": ["folic acid"],
  fructose: ["fructose"],
  levulose: ["fructose"],
  "fruit sugar": ["fructose"],
  "natural sugar": ["turbinado sugar"],
  "fumaric acid": ["fumaric acid"],
  gelatin: ["gelatin"],
  "gellan gum": ["gellan gum"],
  "glutamic acid": ["glutamic acid"],
  gluten: ["wheat gluten", "corn gluten"],
  glyceride: ["glyceride"],
  glycerin: ["glycerol"],
  glycerine: ["glycerol"],
  glycerol: ["glycerol"],
  "glycerine or glycerin": ["glycerol"],
  glycine: ["glycine"],
  "guar gum": ["guar gum"],
  guaran: ["guar gum"],
  "guar flour": ["guar gum"],
  gypsum: ["calcium sulfate"],
  "hexanoic acid": ["caproic acid"],
  "high fructose corn syrup": ["high fructose corn syrup"],
  HFCS: ["high fructose corn syrup"],
  "glucose-fructose": ["high fructose corn syrup"],
  "glucose-fructose syrup": ["high fructose corn syrup"],
  "fructose-glucose syrup": ["high fructose corn syrup"],
  honey: ["honey"],
  humectant: ["humectant"],
  hydrogenation: ["hydrogenation"],
  "hydrogen peroxide": ["hydrogen peroxide"],
  "hydroxypropyl methylcellulose": ["hydroxypropyl methylcellulose"],
  "invert sugar": ["invert sugar"],
  inversol: ["invert sugar"],
  colorose: ["invert sugar"],
  "invert sugar syrup": ["invert sugar syrup"],
  "sugar syrup": ["invert sugar syrup"],
  invert: ["invert sugar syrup"],
  isinglass: ["isinglass"],
  keratin: ["keratin"],
  "L-cysteine/L-cystine": ["L-cysteine/L-cystine"],
  lactalbumin: ["albumin"],
  "lac-resin": ["lac-resin"],
  shellac: ["lac-resin"],
  lactase: ["lactase"],
  "lactic acid": ["lactic acid"],
  "butyl lactate": ["lactic acid"],
  "ethyl lactate": ["lactic acid"],
  lactose: ["carbohydrate"],
  lard: ["lard"],
  "lard oil": ["lard"],
  "pork fat": ["lard"],
  "pork oil": ["lard"],
  "leavening agent": ["leavening agent"],
  leavener: ["leavening agent"],
  lecithin: ["lecithin"],
  phosphatidylcholine: ["lecithin"],
  "partially hydrolyzed lecithin": ["lecithin"],
  E322: ["lecithin"],
  lime: ["lime"],
  lipase: ["lipase"],
  "locust bean gum": ["locust bean gum"],
  "St. John's bread": ["locust bean gum"],
  "carob bean gum": ["locust bean gum"],
  Lutein: ["Lutein"],
  "magnesium stearate": ["magnesium stearate"],
  "maleic acid": ["maleic acid"],
  "malic acid": ["malic acid"],
  "apple acid": ["malic acid"],
  malt: ["malt"],
  "malt extract": ["malt extract"],
  maltodextrins: ["maltodextrins"],
  maltol: ["maltol"],
  maltose: ["maltose"],
  "malt sugar": ["mannitol"],
  mannitol: ["mannitol"],
  "maple sugar": ["maple sugar"],
  "maple syrup": ["maple syrup"],
  "methyl paraben": ["methyl paraben"],
  "Methyl-p-hydroxybenzoate": ["methyl paraben"],
  "mineral oil": ["mineral oil"],
  "white oil": ["mineral oil"],
  "modified foodstarch": ["modified starch"],
  "modified starch": ["pregelatinized starch"],
  molasses: ["molasses"],
  monoglyceride: ["monoglyceride"],
  "monosodium glutamate": ["monosodium glutamate"],
  Accent: ["monosodium glutamate"],
  Zest: ["monosodium glutamate"],
  MSG: ["monosodium glutamate"],
  "myristic acid": ["myristic acid"],
  "n-tetradecanoic acid": ["myristic acid"],
  "natural coloring": ["natural coloring"],
  "natural flavor": ["natural flavor"],
  niacin: ["niacin"],
  "nicotinic acid": ["niacin"],
  "nicotin-amide": ["niacin"],
  niacinamide: ["niacin"],
  "vitamin B-3": ["niacin"],
  "nonnutritive sweetener": ["nonnutritive sweetener"],
  "nutritional yeast": ["nutritional yeast"],
  "nutritive sweetener": ["nutritive sweetener"],
  oil: ["fat"],
  "oleic acid": ["oleic acid"],
  "cis-9-octadecenoic acid": ["oleic acid"],
  oleoresin: ["oleoresin"],
  Olestra: ["Olestra"],
  Olean: ["Olestra"],
  "sucrose polyester": ["Olestra"],
  "oxidizing agent": ["oxidizing agent"],
  "palmitic acid": ["palmitic acid"],
  "n-hexadecanoic acid": ["palmitic acid"],
  pancreatin: ["pancreatin"],
  "pancreatic extract": ["pancreatin"],
  "Hi-Vegi-Lip": ["pancreatin"],
  "pantothenic acid": ["pantothenic acid"],
  "Vitamin B-5": ["pantothenic acid"],
  "d-pantothenamide": ["pantothenic acid"],
  papain: ["papain"],
  Caroid: ["papain"],
  paprika: ["paprika"],
  paraffin: ["paraffin"],
  pepsin: ["pepsin"],
  phenylalanine: ["phenylalanine"],
  polyacrylomite: ["polyacrylomite"],
  polydextrose: ["polydextrose"],
  polyethylene: ["polyethylene"],
  "polyoxyethylene (20) sorbitan monooleate": ["polysorbate 80"],
  "polyoxyethylene (20) sorbitan monostearate  ": ["polysorbate 60"],
  polysorbate: ["polysorbate"],
  "polysorbate 60": ["polysorbate 60"],
  "polyoxyethylene (20) sorbitan monostearate": ["polysorbate 60"],
  "polysorbate 80": ["polysorbate 80"],
  "potassium sorbate": ["potassium sorbate"],
  "sorbic acid potassium salt": ["potassium sorbate"],
  "pregelatinized starch": ["pregelatinized starch"],
  "pregelatinized modified starch": ["pregelatinized starch"],
  "pregelatinized food starch": ["pregelatinized starch"],
  "instant starch": ["pregelatinized starch"],
  "soluble starch": ["pregelatinized starch"],
  "pregel/prejel starch": ["pregelatinized starch"],
  preservative: ["preservative"],
  "antimicrobial agent,antispoilant": ["preservative"],
  "processing aid": ["processing aid"],
  "propionic acid": ["propionic acid"],
  "propylene glycol": ["propylene glycol"],
  "1,2-propanediol": ["propylene glycol"],
  protease: ["protease"],
  protein: ["protein"],
  pyridoxine: ["pyridoxine"],
  "vitamin B-6": ["pyridoxine"],
  "vitamin B-6 hydrochloride": ["pyridoxine"],
  "pyridoxine hydrochloride": ["pyridoxine"],
  "pyridoxol hydrochloride": ["pyridoxine"],
  pyridoxal: ["pyridoxine"],
  pyridoxamine: ["pyridoxine"],
  "reducing agent": ["reducing agent"],
  "refined beet sugar": ["refined beet sugar"],
  rennet: ["rennet"],
  rennin: ["rennin"],
  resin: ["resin"],
  riboflavin: ["riboflavin"],
  "vitamin B-2": ["riboflavin"],
  "lacto-flavin": ["riboflavin"],
  "riboflavin-5-phosphate": ["riboflavin"],
  "rice syrup": ["rice syrup"],
  rosin: ["rosin"],
  colophony: ["rosin"],
  "royal jelly": ["royal jelly"],
  saccharin: ["saccharin"],
  "sodium benzosulfimide": ["saccharin"],
  saccharose: ["sucrose"],
  "sequestering agent": ["sequestering agent"],
  "silicon dioxide": ["silicon dioxide"],
  "silica dioxide": ["silicon dioxide"],
  silica: ["silicon dioxide"],
  "silica gel": ["silicon dioxide"],
  "kieselsol,colloidal silica,E551": ["silicon dioxide"],
  Simplesse: ["Simplesse"],
  "soda ash": ["sodium carbonate"],
  "sodium benzoate": ["sodium benzoate"],
  "sodium bicarbonate": ["sodium bicarbonate"],
  "bicarbonate of soda": ["sodium bicarbonate"],
  "sodium acid carbonate": ["sodium bicarbonate"],
  "sodium hydrogen carbonate": ["sodium bicarbonate"],
  "sodium carbonate": ["sodium carbonate"],
  "sodium caseinate": ["sodium caseinate"],
  "sodium hydroxide": ["sodium hydroxide"],
  "caustic soda": ["sodium hydroxide"],
  "soda lye": ["sodium hydroxide"],
  "sodium nitrate": ["sodium nitrate"],
  "Chile saltpeter": ["sodium nitrate"],
  "sodium pantothenate": ["pantothenic acid"],
  "sodium stearoyl lactylate": ["sodium stearoyl lactylate"],
  "sodium stearoyl-2-lactylate": ["sodium stearoyl lactylate"],
  "sorbic acid": ["sorbic acid"],
  "hexadienic acid": ["sorbic acid"],
  "hexadienoic acid": ["sorbic acid"],
  sorbistat: ["sorbic acid"],
  sorbitan: ["sorbitan"],
  sorbitol: ["sorbitol"],
  stabilizer: ["stabilizer"],
  thickener: ["stabilizer"],
  starch: ["unmodified starch"],
  "stearic acid": ["stearic acid"],
  "n-octadecanoic acid": ["stearic acid"],
  Sucanat: ["Sucanat"],
  "succinic acid": ["succinic acid"],
  Sucralose: ["Sucralose"],
  "refined sugar": ["sucrose"],
  suet: ["tallow"],
  "surface-active agents": ["surface-active agents"],
  surfactants: ["surface-active agents"],
  "surface-finishing agents": ["surface-finishing agents"],
  tallow: ["tallow"],
  "hydrogenated tallow": ["tallow"],
  "tallow flakes": ["tallow"],
  dripping: ["tallow"],
  "tartaric acid": ["tartaric acid"],
  "sodium tartrate": ["tartaric acid"],
  "sodium potassium tartrate": ["tartaric acid"],
  "Rochelle salts": ["tartaric acid"],
  "texturized vegetable protein": ["texturized vegetable protein"],
  TVP: ["texturized vegetable protein"],
  "textured soy flour": ["texturized vegetable protein"],
  "textured soy protein": ["texturized vegetable protein"],
  texturizer: ["texturizer"],
  thiamin: ["thiamin"],
  "Vitamin B-1": ["thiamin"],
  "thiamine hydrochloride": ["thiamin"],
  "thiamine mononitrate": ["thiamin"],
  "thiamine mononitrite": ["thiamin"],
  thiamine: ["thiamin"],
  "thiamin mononitrate": ["thiamin"],
  trypsin: ["trypsin"],
  tumeric: ["turmeric"],
  "turbinado sugar": ["turbinado sugar"],
  "washed raw sugar": ["turbinado sugar"],
  turmeric: ["turmeric"],
  tyrosine: ["tyrosine"],
  "L-tyrosine": ["tyrosine"],
  "unmodified food starch": ["unmodified starch"],
  "unmodified starch": ["unmodified starch"],
  vanilla: ["vanilla"],
  "vanilla extract": ["vanilla extract"],
  vanillin: ["vanillin"],
  vinegar: ["vinegar"],
  "vinegar, distilled": ["distilled vinegar"],
  "vital wheat gluten": ["wheat gluten"],
  vitamin: ["vitamin"],
  "vitamin A": ["vitamin A"],
  "vitamin A acetate": ["vitamin A"],
  "vitamin A palmitate": ["vitamin A"],
  "vitamin A propionate": ["vitamin A"],
  retinol: ["vitamin A"],
  "vitamin B-1": ["thiamin"],
  "vitamin B-5": ["pantothenic acid"],
  "vitamin B-12": ["vitamin B-12"],
  cyanocobalamin: ["vitamin B-12"],
  "vitamin C": ["vitamin C"],
  "iso-ascorbic (erythorbic) acid": ["vitamin C"],
  "sodium ascorbate": ["vitamin C"],
  "sodium isoascorbate": ["vitamin C"],
  "vitamin D2": ["vitamin D2"],
  ergocalciferol: ["vitamin D2"],
  calciferol: ["vitamin D3"],
  "vitamin D3": ["vitamin D3"],
  cholecalciferol: ["vitamin D3"],
  "activated 7-dehydrocholesterol": ["vitamin D3"],
  "vitamin E": ["vitamin E"],
  tocopherol: ["vitamin E"],
  wax: ["wax"],
  "wheat gluten": ["wheat gluten"],
  "wheat isolate": ["wheat gluten"],
  whey: ["whey"],
  wine: ["wine"],
  "xanthan gum": ["xanthan gum"],
  "corn sugar gum": ["xanthan gum"],
  "yeast extract": ["autolyzed yeast extract"],
  Zeaxanthin: ["Zeaxanthin"],
  zein: ["zein"]
};
exports.default = ingNameToInfoKeys;