"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var infoKeyToInfoDetails_1 = require("./infoKeyToInfoDetails");

exports.CATEGORY = infoKeyToInfoDetails_1.CATEGORY;

var ingNameToInfoKeys_1 = require("./ingNameToInfoKeys");

exports.default = {
  ingNameToInfoKeys: ingNameToInfoKeys_1.default,
  infoKeyToInfoDetails: infoKeyToInfoDetails_1.default
};